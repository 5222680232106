
















import {
  defineComponent,
  reactive,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { debounce } from "lodash";
import { GenericListAPI } from "@/apps/core/api/list";

export default defineComponent({
  name: "GenericAutocomplete",
  props: {
    apiPath: { type: String, required: true },
    params: Object,
    formatOption: Function,
    placeholder: String,
    required: { type: Boolean, default: true },
    value: Object,
  },
  setup(props, { emit }) {
    const ac: Ref<any> = ref(null);
    const isFetching = ref(false);
    const blank = { id: null, nama: null };
    // pilih field yang digunakan saja (id dan nama)
    const initValue = props.value
      ? { id: props.value.id, nama: props.value.nama }
      : blank;
    const valueData = reactive(Object.assign({}, initValue));
    const namaData = ref<string | null>(valueData.nama);
    const api = new GenericListAPI(props.apiPath);
    const options = reactive(api.options);
    let optionsMap = {} as Record<string, Record<string, string>>;

    const emitInput = () => {
      emit("input", valueData.id === null ? null : valueData);
    };

    const onSelect = (value: Record<string, string>) => {
      const adjValue = optionsMap[value?.id] ?? blank;
      Object.assign(valueData, adjValue);
      emitInput();
      const divEl: HTMLElement = ac.value.$el;
      const el = divEl.getElementsByTagName("input")[0];
      el.focus();
    }

    const validateInput = () => {
      const divEl: HTMLElement = ac.value.$el;
      const el = divEl.getElementsByClassName("dropdown-menu")[0];
      const style = getComputedStyle(el);
      const displayStyle = style.getPropertyValue("display");
      if (displayStyle === "none") {
        const namaArr = options.map((el) => el.nama);
        const validNama = namaArr.includes(namaData.value ?? "");
        if (!validNama) {
          if ((namaData.value ?? "").trim().length > 0) {
            emit("invalid", namaData.value);
          }
          Object.assign(valueData, blank);
          namaData.value = null;
          emitInput();
          if (ac.value) {
            const divEl: HTMLElement = ac.value.$el;
            const el = divEl.getElementsByTagName("input")[0];
            el.checkValidity();
          }
        }
        emit("blur");
      }
    }

    const onBlur = () => {
      // ditambahkan timeout/delay,
      // agar menunggu dropdown tertutup dulu, jika memang akan tertutup
      setTimeout(validateInput, 200);
    }

    const fetchOptions = async (inputStr: string) => {
      if (inputStr.length < 3) {
        api.resetOptions();
        return;
      }
      try {
        isFetching.value = true;
        const params = props.params ?? {};
        params.q = inputStr;
        await api.fetch(params);
        optionsMap = {};
        api.options.forEach(
          (option) =>
            (optionsMap[option.id] = props.formatOption
              ? props.formatOption(option)
              : option)
        );
      } catch (error) {
        api.resetOptions();
      } finally {
        isFetching.value = false;
      }
    }

    watch(
      () => props.value,
      (newValue) => {
        // Memonitor perubahan dari props, set valueData
        // dengan value baru agar render ulang component
        if (!newValue || !newValue.id) {
          Object.assign(valueData, blank);
          namaData.value = null;
        } else if (valueData.id !== newValue.id) {
          Object.assign(valueData, { id: newValue.id, nama: newValue.nama });
          namaData.value = valueData.nama;
        }
      }
    );

    // fetchOptions tidak bisa disimpan di method,
    // karena debounce mengganggu keyword this.
    const fetchOptionsTyping = debounce(fetchOptions, 500);

    return {
      // Data
      ac,
      fetchOptionsTyping,
      isFetching,
      namaData,
      options,

      // Method
      onBlur,
      onSelect,
    };
  },
});
